import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useGetProductsQuery } from '../slices/productsApiSlice';
import { Link } from 'react-router-dom';
import Product from '../components/Product';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Paginate from '../components/Paginate';
import ProductCarousel from '../components/ProductCarousel';
import Meta from '../components/Meta';

import { useSelector } from 'react-redux';
import NavbarCategory from './NavbarCategory';

import { useLocation } from 'react-router-dom';
import useWxLogin from '../useHooks/useWxLogin';
import useWxShare from '../useHooks/useWxShare';
const HomeScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const location = useLocation();
  useWxLogin();
  const { pageNumber, keyword, category } = useParams();
  const { data, isLoading, error } = useGetProductsQuery({
    keyword,
    category,
    pageNumber,
  });
  useWxShare(location, userInfo);

  return (
    <>
      {!keyword ? (
        <ProductCarousel />
      ) : (
        <Link to='/' className='btn btn-light mb-4'>
          {process.env.REACT_APP_CHINESE ? '返回' : 'Go Back'}
        </Link>
      )}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Meta title={process.env.REACT_APP_TITLE} />
          <h1>
            {process.env.REACT_APP_CHINESE ? '最新商品' : 'Latest Products'}
          </h1>
          <Row>
            <NavbarCategory
              pages={data.pages}
              page={data.page}
              keyword={keyword ? keyword : ''}
            />
            {data.products.map((product) => (
              <Col key={product._id} xs={12} sm={12} md={6} lg={4} xl={3}>
                <Product product={product} />
              </Col>
            ))}
          </Row>
          <Paginate
            pages={data.pages}
            page={data.page}
            category={category ? category : ''}
            keyword={keyword ? keyword : ''}
          />
        </>
      )}
    </>
  );
};

export default HomeScreen;
