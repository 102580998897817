import { useEffect } from 'react';
import axios from 'axios';
import wx from 'weixin-js-sdk';

const useWxShare = (location, userInfo, title = null, imageUrl = null) => {
  useEffect(() => {
    const wechatConfig = async () => {
      console.log('wechatConfig initiated...');
      const url = encodeURIComponent(window.location.href.split('#')[0]);
      try {
        const { data } = await axios.get(
          `https://gzh.whtec.net/jsapi?url=${url}`
        );
        wx.config({
          debug: false,
          ...data,
          jsApiList: ['updateTimelineShareData', 'updateAppMessageShareData'],
        });

        wx.ready(() => {
          wx.checkJsApi({
            jsApiList: ['updateTimelineShareData', 'updateAppMessageShareData'],
            success: () => {
              console.log('JS APIs available');
            },
          });

          wx.updateTimelineShareData({
            title: title ? title : process.env.REACT_APP_TITLE,
            link: window.location.href,
            imgUrl: imageUrl
              ? imageUrl
              : `${window.location.origin}/images/airLink.png`,
            success: () => {
              console.log('Timeline share data updated');
            },
          });

          wx.updateAppMessageShareData({
            title: title ? title : process.env.REACT_APP_TITLE,
            desc: `快递号：${location.pathname.split('/').pop()}`,
            link: window.location.href,
            imgUrl: imageUrl
              ? `${window.location.origin}${imageUrl}`
              : `${window.location.origin}/images/airLink.png`,
            success: () => {
              console.log('Message share data updated');
            },
          });
        });

        wx.error((res) => {
          console.error('WeChat config error:', res);
        });
      } catch (error) {
        console.error('Error during wechatConfig:', error);
      }
    };

    if (navigator.userAgent.toLowerCase().includes('micromessenger')) {
      wechatConfig();
    }
  }, [location, userInfo,title,imageUrl]);
};

export default useWxShare;
